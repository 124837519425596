<template>
    <div class="item-frames">
        <div class="tool-bar">
            <el-button type="primary" class="tool-btn" @click="update_frame('new')">הוסף מסגרת</el-button>
        </div>
        <div class="state-manager">
            <div class="product-manage" v-if="!state">
                <div class="search-bar">
                    <el-input placeholder="חיפוש" v-model="search_string" :input-style="input_options"/>
                </div>
                <table id="table" v-if="filtered_items">
                    <tr>
                        <th>מקט</th>
                        <th>פריט</th>
                        <th>מסגרת</th>
                        <th>עריכה</th>
                    </tr>
                    <template v-for="item in filtered_items" :key="item.makat">
                        <tr>
                            <td>{{item.makat}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.frame}}</td>
                            <td style="cursor:pointer;" @click="update_frame(item)"><i class="material-icons">edit</i></td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core';
import { projectFirestore } from '../../../firebase/config';
import Swal from 'sweetalert2';
import { slide_pop_successs } from '../../../Methods/Msgs';
export default {
setup(){
    const state = ref(null);
    const search_string = ref('');
    const input_options = {
        'background': 'var(--main)',
        'color': 'white'
    }
    const items = ref([]);

    const filtered_items = ref(computed(() => {
        if(search_string.value.length == 0){
            return items.value
        }else{
            return items.value.filter(itm => {
                return itm.name.includes(search_string.value) || itm.makat.includes(search_string.value) ||
                itm.frame.includes(search_string.value)
            })
        }
        
    }))
    
    const frames = {
        'ירוק': 'ירוק',
        'כחול': 'כחול',
        'אדום': 'אדום'
    }

    const get_items = async () => {
        const docs = await projectFirestore.collection('Products').where('frame', '>', '').get()
        if(!docs.empty){
            items.value = docs.docs.map(doc => doc.data());
        }
    }

    const update_frame = (item) => {
        if(item != 'new'){
            Swal.fire({
                icon: 'question',
                title: 'עדכון מסגרת',
                html: `הנך מעדכן מסגרת לפריט ${item.name}. <br> מסגרת נוכחית: ${item.frame}<br><br> <p style="font-size:20px; text-decoration:underline;">בחר את צבע המסגרת הרצויה:</p>`,
                input: 'select',
                inputOptions: frames,
                inputPlaceholder: 'בחר מרשימה',
                confirmButtonText: 'עדכן מסגרת',
                cancelButtonText: 'ביטול',
                denyButtonText: 'מחק מסגרת',
                showDenyButton: true,
                showCancelButton: true
            }).then(async res => {
                if(res.isConfirmed){
                    await projectFirestore.collection('Products').doc(item.makat).update({frame: res.value})
                    let inx = items.value.findIndex(itm => itm.makat == item.makat)
                    items.value[inx].frame = res.value
                    slide_pop_successs("מסגרת עודכנה בהצלחה!")
                }
                if(res.isDenied){
                    await projectFirestore.collection('Products').doc(item.makat).update({frame: null})
                    let inx = items.value.findIndex(itm => itm.makat == item.makat)
                    items.value.splice(inx, 1);
                    slide_pop_successs("מסגרת הוסרה בהצלחה!")
                }
            })
        }else{
            Swal.fire({
                icon: 'question',
                title: 'הוספת מסגרת לפריט',
                text: 'אנא הכנס את מקט/ים הפריט/ים',
                input: 'text',
                showCancelButton: true,
                cancelButtonText: 'ביטול',
                confirmButtonText: 'בדוק מקט'
            }).then(async res => {
                if(res.isConfirmed && res.value.length >= 4 && !res.value.includes(',')){
                    const doc = await projectFirestore.collection('Products').doc(res.value).get()
                    if(doc.exists){
                        Swal.fire({
                            icon: 'question',
                            title: doc.data().name,
                            text: 'בחר מסגרת לפריט',
                            input: 'select',
                            inputOptions: frames,
                            inputPlaceholder: 'בחר מרשימה',
                            confirmButtonText: 'עדכן מסגרת',
                            cancelButtonText: 'ביטול',
                            showCancelButton: true
                        }).then(async res => {
                            if(res.isConfirmed){
                                let item  = doc.data()
                                await projectFirestore.collection('Products').doc(item.makat).set({frame: res.value}, {merge:true})
                                item.frame = res.value;
                                items.value.push(item);
                                slide_pop_successs("מסגרת נוספה בהצלחה!")
                            }
                        })
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title:"פריט לא נמצא"
                        })
                    }
                }
                if(res.isConfirmed && res.value.length >= 4 && res.value.includes(',')){
                    const makats = res.value.split(',')
                    let item_list = ''
                    let items_obj = []
                    for(const makat of makats){
                        const doc = await projectFirestore.collection('Products').doc(makat).get()
                        if(!doc.exists){
                            Swal.fire({
                                icon: 'error',
                                title:`פריט ${makat} לא נמצא`
                            })
                            return
                        }
                        items_obj.push(doc.data())
                        item_list+= `<li>${doc.data().name}<li/>`
                    }
                    Swal.fire({
                            icon: 'question',
                            title: 'רשימת הפריטים',
                            html:`
                                <ul style="list-style-type: none">
                                    ${item_list}
                                <ul/>
                            `,
                            text: 'בחר מסגרת לפריטים',
                            input: 'select',
                            inputOptions: frames,
                            inputPlaceholder: 'בחר מרשימה',
                            confirmButtonText: 'עדכן מסגרת',
                            cancelButtonText: 'ביטול',
                            showCancelButton: true
                        }).then(async res => {
                            if(res.isConfirmed){
                                for(const item of items_obj){
                                    await projectFirestore.collection('Products').doc(item.makat).set({frame: res.value}, {merge:true})
                                    item.frame = res.value;
                                    items.value.push(item);
                                    setTimeout(() => {
                                        slide_pop_successs(`מסגרת פריט ${item.makat} נוספה בהצלחה`)
                                    }, 500);
                                }
                            }
                    })
                }
            })
        }
    }

    if(items.value.length==0){
        get_items();
    }

    return{
        state, search_string, input_options, filtered_items, frames, update_frame
    }
}
}
</script>

<style scoped>
.item-frames{
    width: 100%;
    height: 100%;
}
.tool-bar{
    width: 100%;
    height: 80px;
    background: var(--alert-purple);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
}
.tool-btn{
    height: 100%;
    margin-left: 8px;
}
.state-manager{
    width: 100%;
    height: calc(100% - 80px);
}
.product-manage{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.search-bar{
    margin-top: 5px;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
@media only screen and (max-width: 660px) {

}
</style>